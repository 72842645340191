<template>
  <div class="container mw-fit-content">
    <Table
      :thead="thead"
      :data="data"
      :search="false"
      :excel="false"
      :searchBox="true"
      tableTitle="Müşteri Listesi"
    >
      <template v-slot:button>
        <router-link :to="{ name: 'Customer' }" class="btn btn-filled-primary">
          Müşteri Ekle
          <i class="icon icon-add ml-10"></i>
        </router-link>
      </template>
      <template v-slot:cell-edit="{ row: data }">
        <router-link
          :to="{ name: 'CustomerEdit', params: { id: data.id } }"
          class="btn btn-empty-primary minw-auto"
        >
          <i class="icon icon-message-edit"></i>
        </router-link>
      </template>
      <template v-slot:cell-name="{ row: data }">
        {{ data.name }}
      </template>
      <template v-slot:cell-email="{ row: data }">
        {{ data.email }}
      </template>
      <!-- <template v-slot:cell-city="{ row: data }">
        {{ data.city }}
      </template>
      <template v-slot:cell-town="{ row: data }">
        {{ data.town }}
      </template> -->
      <template v-slot:cell-delete="{ row: data }">
        <button class="btn btn-empty-danger" @click="deleteCustomer(data.id)">
          <i class="icon icon-trash"></i>
        </button>
      </template>
    </Table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
    };
  },
  created() {
    this.getCompanies();
    this.$store.commit("theme/addToBreadcrumps", [
      {
        title: "Anasayfa",
        path: "/",
      },
      {
        title: "Müşteriler",
        path: "/customers",
      },
    ]);
  },
  methods: {
    deleteCustomer(customerId) {
      this.$axios.delete("/customer/").then((response) => {
        this.getCompanies();
      });
    },
    getCompanies() {
      this.$axios.get("/customer/").then((response) => {
        response.data.filter((customer) => {
          this.data.push({
            id: customer.id,
            name: customer.name,
            email: customer.contact_email,
            // city: customer.city,
            // town: customer.town,
          });
        });
      });
    },
  },
  setup() {
    const thead = [
      {
        label: "Düzenle",
        value: "edit",
        searchable: false,
      },
      {
        label: "Müşteri Adı",
        value: "name",
        searchable: true,
      },
      {
        label: "Email",
        value: "email",
        searchable: true,
      },
      // {
      //   label: "İl",
      //   value: "city",
      //   searchable: true,
      // },
      // {
      //   label: "İlçe",
      //   value: "town",
      //   searchable: true,
      // },
      {
        label: "Sil",
        value: "delete",
        searchable: false,
      },
    ];
    return {
      thead,
    };
  },
};
</script>